import React from 'react'
import { cn } from '../lib/helpers'

import * as styles from './tooltip.module.css'

const Tooltip = ({ text, inline, children }) => (
  <div className={cn(styles.container, inline && styles.inline)}>
    {children}
    <div className={cn(styles.tooltip, inline && styles.inline)}>{text}</div>
  </div>
)

export default Tooltip
