// extracted by mini-css-extract-plugin
export var root = "pricing-module--root--a1KSW";
export var buttons = "pricing-module--buttons--jmviY";
export var mode = "pricing-module--mode--t+tCr";
export var modeItem = "pricing-module--modeItem--mWxfL";
export var active = "pricing-module--active--Ht6Wp";
export var cards = "pricing-module--cards--byAy+";
export var card = "pricing-module--card--9PPpr";
export var head = "pricing-module--head--pdKfh";
export var title = "pricing-module--title--KEPrH";
export var users = "pricing-module--users--Adyf7";
export var benefits = "pricing-module--benefits--zpqVH";
export var benefit = "pricing-module--benefit--rbd08";
export var price = "pricing-module--price--+Lzru";
export var priceUnit = "pricing-module--priceUnit--6FBbH";
export var freeFeatures = "pricing-module--freeFeatures--NGUJn";
export var menu = "pricing-module--menu--o42Oq";
export var stats = "pricing-module--stats--ZpG0R";
export var green = "pricing-module--green--x61tK";
export var calculate = "pricing-module--calculate--l2lRT";
export var range = "pricing-module--range--c9CaW";
export var usersTooltip = "pricing-module--usersTooltip--OMJYE";
export var marks = "pricing-module--marks--PWmfe";
export var container = "pricing-module--container--L4WlF";
export var nonProfit = "pricing-module--nonProfit--pJRt0";
export var icon = "pricing-module--icon--kdnnb";
export var wrapper = "pricing-module--wrapper--Zz8Kh";
export var nonProfitTail = "pricing-module--nonProfitTail--uNd9u";