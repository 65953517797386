import React, { useState } from 'react'
import { cn, getLangIndex } from '../../lib/helpers'

import * as styles from './pricing.module.css'
import RocketIcon from '../icons/rocket'
import PersonIcon from '../icons/person'
import BenefitIcon from '../icons/benefit'
import Tooltip from '../tooltip'
import Button from '../button'

const Pricing = ({ pricings, data, lang }) => {
  const [mode, setMode] = useState(false)
  const [userCount, setCount] = useState(3)
  const currentLang = getLangIndex(pricings, lang)

  const setUserCount = (count) => {
    if (count >= 3 && count <= 50) {
      setCount(count)
    }
  }

  const amount = (userCount = 1) => {
    const price = mode
      ? pricings[currentLang].priceCards[1].priceYear
      : pricings[currentLang].priceCards[1].priceMonth

    // получаем единицу валюты из цены
    const unit = price.split(parseFloat(price)).join('')

    return Math.ceil(parseFloat(price) * userCount * (mode ? 12 : 1)) + unit
  }

  const buttons = (buttons) => {
    return buttons && buttons.map((b, i) => (
      <Button to={b.to} outlined={b.outlined} key={i}>
        {b.caption}
      </Button>
    ))
  }

  const marks = () => {
    const res = []
    for (let i = 0; i <= 50; i += 5) {
      res.push(<span key={i} onClick={() => setUserCount(i)}>{i}</span>)
    }
    return res
  }

  const details = (details) => {
    return details && details.map((b, i) => (
      <p key={i} className={styles.benefit}>
        <BenefitIcon />
        <span>{b}</span>
      </p>
    ))
  }

  const priceCards = () => {
    if (!pricings[currentLang].priceCards) {
      return []
    }
    return pricings[currentLang].priceCards.map((pc, i) => (
      <div className={cn(styles.card, pc.popular && styles.active)} key={i}>
        <div className={styles.head}>
          <div className={styles.title}>{pc.title}</div>
          <div className={styles.users}>
            <span>{pc.usersCount || '3-50'}</span>
            <PersonIcon />
          </div>
        </div>
        <p>
          {pc.description || 'For single quality assurance engineers and small companies.'}
        </p>
        <div className={styles.price}>
          {mode ? pc.priceYear : pc.priceMonth}
        </div>
        {pc.priceUnit &&
          <span className={styles.priceUnit}>
            {pc.priceUnit}
          </span>}

        {pc.freeFeatures &&
          <p className={styles.freeFeatures}>
            {pc.freeFeatures || 'Free features:'}
          </p>}

        <div className={styles.benefits}>
          {pc.details && details(pc.details)}
        </div>

        {pc.buttons && buttons(pc.buttons)}
      </div>
    ))
  }

  return (
    <div className={styles.root}>
      <h1>{pricings[currentLang].pageTitle || 'Pricing'}</h1>
      <h2>{pricings[currentLang].pageSubtitle || 'Choose a plan for your team'}</h2>

      <div className={styles.buttons}>
        <Button white={mode} onClick={() => setMode(false)}>
          {pricings[currentLang].monthCaption}
        </Button>
        <Tooltip text={pricings[currentLang].yearDiscount}>
          <Button white={!mode} onClick={() => setMode(true)}>
            {pricings[currentLang].yearCaption}
          </Button>
        </Tooltip>
      </div>

      <div className={styles.cards}>
        {pricings[currentLang].priceCards && priceCards()}
      </div>

      <div className={styles.calculate}>
        <h2>{pricings[currentLang].pageCalculate || 'Calculate your Standard plan'}</h2>

        <div className={styles.menu}>
          <div className={styles.buttons}>
            <Button white={mode} onClick={() => setMode(false)}>
              {pricings[currentLang].monthCaption}
            </Button>
            <Tooltip text={pricings[currentLang].yearDiscount} inline>
              <Button white={!mode} onClick={() => setMode(true)}>
                {pricings[currentLang].yearCaption}
              </Button>
            </Tooltip>
          </div>

          <div className={styles.stats}>
            <span>
              {pricings[currentLang][mode ? 'pageCalculatePPUYear' : 'pageCalculatePPU']} <b>{amount()}</b>
            </span>
            <span>
              {pricings[currentLang][mode ? 'pageCalculateTotalYear' : 'pageCalculateTotal']} <b className={styles.green}>{amount(userCount)}</b>
            </span>
          </div>
        </div>

        <div className={styles.marks}>{marks()}</div>

        <input className={styles.range} type='range' onChange={val => setUserCount(parseInt(val.target.value))} min='0' max='50' value={'' + userCount} step='1' />
        <div className={styles.usersTooltip} style={{ left: `calc(${(userCount) / 0.51}% - 13px)` }}>
          <span>{userCount}</span>
          <PersonIcon />
        </div>

      </div>

      <div className={styles.container}>
        <div className={styles.nonProfit}>
          <div className={styles.icon}>
            <RocketIcon />
          </div>
          <div className={styles.wrapper}>
            <h3>
              {pricings[currentLang].pageNonProfit || 'Non-profit organization or Startup?'}
            </h3>
            <p>
              {pricings[currentLang].pageNonProfitText || `We support non-profit organizations, universities, educational,
              open-source projects and startups and provide them a special
              discount on subscription.`}
            </p>
            <p>
              <Button to={pricings[currentLang].pageNonProfitLink.to} asLink>
                {pricings[currentLang].pageNonProfitLink.caption || 'Contact us '}
              </Button>
              <span className={styles.nonProfitTail}>{pricings[currentLang].pageNonProfitTail || ' to get a special offer.'}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
