import React from 'react'

const BenefitIcon = () => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='16'
    height='16'
  >
    <path d="M6.47378 7.5267C6.34825 7.40117 6.17798 7.33064 6.00045 7.33064C5.82291 7.33064 5.65265 7.40117 5.52712 7.5267C5.40158 7.65224 5.33105 7.8225 5.33105 8.00004C5.33105 8.17757 5.40158 8.34783 5.52712 8.47337L7.52712 10.4734C7.58941 10.5352 7.66329 10.584 7.74451 10.6172C7.82574 10.6504 7.91271 10.6672 8.00045 10.6667C8.09172 10.6638 8.18141 10.6422 8.26398 10.6032C8.34655 10.5642 8.42023 10.5087 8.48045 10.44L13.1471 5.1067C13.2549 4.97292 13.3068 4.80271 13.2921 4.63156C13.2775 4.46041 13.1973 4.30152 13.0683 4.18804C12.9394 4.07456 12.7716 4.01523 12.6 4.02244C12.4283 4.02964 12.2661 4.10281 12.1471 4.2267L8.00045 9.0267L6.47378 7.5267Z" fill="#05A081"/>
    <path d="M14.0002 7.33337C13.8234 7.33337 13.6538 7.40361 13.5288 7.52864C13.4038 7.65366 13.3335 7.82323 13.3335 8.00004C13.3335 9.41453 12.7716 10.7711 11.7714 11.7713C10.7712 12.7715 9.41469 13.3334 8.0002 13.3334C6.94693 13.3329 5.91738 13.0205 5.04138 12.4357C4.16538 11.8509 3.48215 11.0198 3.07785 10.0472C2.67356 9.07463 2.5663 8.0041 2.76959 6.97064C2.97289 5.93717 3.47764 4.98703 4.2202 4.24004C4.714 3.73963 5.30265 3.34274 5.95171 3.07258C6.60076 2.80243 7.29717 2.66445 8.0002 2.66671C8.4265 2.66938 8.85124 2.71856 9.26687 2.81337C9.35384 2.84028 9.44541 2.84908 9.53592 2.83924C9.62643 2.8294 9.71397 2.80113 9.79313 2.75616C9.8723 2.71119 9.94141 2.65049 9.99621 2.57779C10.051 2.50509 10.0904 2.42193 10.1118 2.33345C10.1332 2.24497 10.1363 2.15303 10.1209 2.06331C10.1054 1.97359 10.0717 1.88798 10.0219 1.81177C9.97212 1.73556 9.90723 1.67035 9.83126 1.62018C9.75529 1.57001 9.66985 1.53593 9.5802 1.52004C9.0623 1.3982 8.53224 1.33558 8.0002 1.33337C6.68309 1.34022 5.39752 1.73705 4.30573 2.47381C3.21394 3.21056 2.36485 4.25422 1.86561 5.47306C1.36638 6.69191 1.23937 8.03132 1.50062 9.32228C1.76187 10.6132 2.39967 11.7979 3.33353 12.7267C4.57133 13.9651 6.2493 14.6626 8.0002 14.6667C9.76831 14.6667 11.464 13.9643 12.7142 12.7141C13.9645 11.4638 14.6669 9.76815 14.6669 8.00004C14.6669 7.82323 14.5966 7.65366 14.4716 7.52864C14.3466 7.40361 14.177 7.33337 14.0002 7.33337Z" fill="#05A081"/>
  </svg>
)

export default BenefitIcon