import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, getLangIndex, getPageLang } from '../lib/helpers'
import Container from '../components/container'
import Layout from '../containers/layout'

import Pricing from '../components/pricing/pricing'

export const query = graphql`
  query PricingPageQuery {
    pricings: allSanityPricing(limit: 6) {
      edges {
        node {
          title
          pageTitle
          pageSubtitle
          monthCaption
          yearCaption
          yearDiscount
          priceCards {
            title
            description
            priceMonth
            priceYear
            priceUnit
            details
            usersCount
            popular
            freeFeatures
            buttons {
              caption
              to
              outlined
            }
          }
          pageCalculate
          pageCalculatePPU
          pageCalculatePPUYear
          pageCalculateTotal
          pageCalculateTotalYear
          pageNonProfit
          pageNonProfitText
          pageNonProfitLink {
            caption
            to
            outlined
          }
          pageNonProfitTail
        }
      }
    }
  }
`

const PricingPage = ({ data, pageContext }) => {
  const pricings = (data || {}).pricings
    ? mapEdgesToNodes(data.pricings)
    : []

  const lang = getPageLang(pageContext)
  const currentLang = getLangIndex(pricings, lang)

  return (
    <Layout pageContext={pageContext} title={pricings[currentLang].pageTitle}>
      <Container>
        <Pricing
          pricings={pricings}
          lang={lang}
        />
      </Container>
    </Layout>
  )
}

export default PricingPage
