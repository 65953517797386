import React from 'react'

const PersonIcon = () => (
  <svg
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='16'
    height='16'
  >
    <circle cx="8" cy="5" r="2.25" stroke="#05A081" strokeWidth="1.5"/>
    <path d="M4 13C4 11.5 4.40002 9 8.00002 9C11.6 9 12 11.5 12 13" stroke="#05A081" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

export default PersonIcon